import React from 'react'

import {DirectionsRenderer, GoogleMap, Marker, Polyline, useJsApiLoader} from '@react-google-maps/api';

let containerStyle = {
    width: '100%',
    height: '175px'
};


const center = {
    // lat: 41.850033, lng: -87.6500523
    lat: 18.52043, lng: 73.856743
};

function RatesMap(props) {


    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDaLZOaYvwAJGsem6pjU8Vg80he7vi2uf0"
    })
    const [map, setMap] = React.useState(null)
    const [directions, setDirections] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {

        const directionsService = new window.google.maps.DirectionsService();


        const origin = {lat: props.latlng.pickCityLat, lng: props.latlng.pickCityLng};
        const destination = {lat: props.latlng.dropCityLat, lng: props.latlng.dropCityLng};

        // if (props.latlng.isMobile) {
        //     containerStyle = {
        //         width: '100%',
        //         height: '155'
        //     };
        // }

        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );

        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);


        setMap(map)
    }, [props.latlng.dropCityLat, props.latlng.dropCityLng, props.latlng.pickCityLat, props.latlng.pickCityLng])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (

        <div className="">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}

            >
                <DirectionsRenderer directions={directions}></DirectionsRenderer>

                <></>
            </GoogleMap>
        </div>
    ) : <></>
}

export default React.memo(RatesMap)